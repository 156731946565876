<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet v-bind:title="'Best Sellers'">
          <template v-slot:title>
            Сведения о движении учащихся 1-11 классов
          </template>
          <template v-slot:toolbar>
          </template>
          <template v-slot:body>
            <b-form>
              <div class="row">
                <div class="col-4">
                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("studentGroups.list.year") }}
                    </template>
                    <b-form-select
                        v-model="filter.educationYear"
                        :options="educationYears"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-4">
                  <b-form-group>
                    <template v-slot:label>
                      Четверть
                    </template>
                    <b-form-select
                        v-model="filter.quarter"
                        :options="quarterOptions"
                    ></b-form-select>
                  </b-form-group>
                </div>
              </div>
              <b-button type="button" @click="reloadData" variant="primary"
                        :class="{'kt-spinner kt-spinner--light disabled':loading }"
              >{{
                  $t("common.filter")
                }}
              </b-button>

              <b-button type="button" @click="exportData" variant="primary" style="margin-left: 10px"
                        :class="{'kt-spinner kt-spinner--light disabled':loading }"
              >Экспорт</b-button>
            </b-form>
            <table class="table table-bordered">
              <thead class="thead-light">
              <tr>
                <th rowspan="2">Классы</th>
                <th rowspan="2">Количество учащихся на начало четверти</th>
                <th colspan="10">Выбыло</th>
                <th colspan="10">Прибыло</th>
                <th rowspan="2">Количество учащихся на конец четверти</th>
                <th rowspan="2">Мальчики</th>
                <th rowspan="2">Девочки</th>
              </tr>
              <tr>
                <th>Школы района</th>
                <th>Школы города</th>
                <th>Республика</th>
                <th>Страны СНГ</th>
                <th>За рубеж</th>
                <th>Из-за работы</th>
                <th>СУЗы</th>
                <th>Внутри школы</th>
                <th>Другие причины</th>
                <th>Всего</th>
                
                <th>Школы района</th>
                <th>Школы города</th>
                <th>Республика</th>
                <th>Страны СНГ</th>
                <th>За рубеж</th>
                <th>На работу</th>
                <th>СУЗы</th>
                <th>Внутри школы</th>
                <th>Другие причины</th>
                <th>Всего</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, k) in reports" :key="k">
                <td>{{ item.level }}</td>
                <td>{{ item.numberAtStart }}</td>
                
                <td>{{ item.dropInsideTheRaion }}</td>
                <td>{{ item.dropInsideTheOblast }}</td>
                <td>{{ item.dropInsideTheRepublic }}</td>
                <td>{{ item.dropSng }}</td>
                <td>{{ item.dropOutsideTheRepublic }}</td>
                <td>{{ item.dropWork }}</td>
                <td>{{ item.dropVuz }}</td>
                <td>{{ item.dropOther }}</td>
                
                <td>{{ item.dropOther }}</td>
                <td>{{ item.dropAll }}</td>
                
                <td>{{ item.arrivalInsideTheRaion }}</td>
                <td>{{ item.arrivalInsideTheOblast }}</td>
                <td>{{ item.arrivalInsideTheRepublic }}</td>
                <td>{{ item.arrivalSng }}</td>
                <td>{{ item.arrivalOutsideTheRepublic }}</td>
                <td>{{ item.arrivalVuz }}</td>
                <td>{{ item.arrivalVuz }}</td>
                <td>{{ item.arrivalFirstClass }}</td>
                <td>{{ item.arrivalOther }}</td>
                <td>{{ item.arrivalAll }}</td>
                
                <td>{{ item.numberAtEnd }}</td>

                <td>{{ item.numberOfBoys }}</td>
                <td>{{ item.numberOfGirls }}</td>
              </tr>
              </tbody>
            </table>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>
<style>
</style>
<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import DictionariesService from "@/common/dictionaries.service";
import ApiService from "@/common/api.service";

export default {
  name: "SchoolStudentMovementReport",
  components: {
    KTPortlet
  },
  data() {
    return {
      filter: {
        quarter: 1,
        educationYear: DictionariesService.currentYear(),
      },
      data: {},
      quarterOptions: DictionariesService.quarterOptions(),
      educationYears: DictionariesService.educationYearOptions(),
      loading: false,
      reports: [],
    };
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    reloadData() {
      this.filter['export'] = false;
      let $this = this;
      $this.loading = true;
      ApiService.postSecured("schoolReports/studentsMovementReport", this.filter).then(({data}) => {

        $this.data = data;
        
        for (let i in $this.data){
          let ii  = $this.data[i];
          
          let reports = ii.groups1To4;          
          ii.groups1To4Sum.level = "1-4";          
          reports.push(ii.groups1To4Sum);

          reports = reports.concat(ii.groups5To9);          
          ii.groups5To9Sum.level = "5-9";
          reports.push(ii.groups5To9Sum);

          reports = reports.concat(ii.groups10To11);          
          ii.groups10To11Sum.level = "10-11";
          reports.push(ii.groups10To11Sum);
          
          ii.groups1To11Sum.level = "Все";
          reports.push(ii.groups1To11Sum);

          $this.reports = reports;
          
          break;
        }
        
        console.log($this.reports);
      }).catch(({response}) => {
        console.log(response);
      }).finally(function () {
        $this.loading = false;
      });
    },
    exportData() {
      let filters = this.filter;
      let $this = this;
      $this.loading = true;
      filters['export'] = true;
      ApiService.downloadSecured("schoolReports/studentsMovementReport", filters)
          .then(({data}) => {
            const url = URL.createObjectURL(new Blob([data], {
              type: 'application/vnd.ms-excel'
            }));
            const link = document.createElement('a');
            link.href = url;

            link.setAttribute('download', 'Отчет.xlsx');
            document.body.appendChild(link);
            link.click();
          })
          .catch(({response}) => {
            console.log(response);
          }).finally(function () {
        $this.loading = false;
      });
    },
  }
};
</script>
